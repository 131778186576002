import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionGet } from '../../utils/connection';
import Slide from './slide';
import OrgForm from '../forms/org.form';
import BrandForm from '../forms/brand.form';
import BrandRow from './brand/brand.row';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

/* This example requires Tailwind CSS v2.0+ */
const people = [
  { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
  // More people...
]
const Orgs = inject("UserStore")(
  observer(
    class OrgList extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          open:false,
          openBrand:false,
          org:null,
          brand:null,
          orgList:[]
        }
    	}

      componentDidMount(){
        this.load();
      }

      load = () => {
        connectionGet('organizations', {}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              orgList:res.data.organization_list
            })
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      map = (org) => {
        connectionGet('map/' + org.hash_id, {}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            console.log(res.data.map_json)
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      openOrg = () => {
        this.setState({
          open:true,
          openBrand:false
        })
      }

      openBrandNew = (org) => {
        this.setState({
          org:org,
          open:false
        },()=>{
          this.setState({
            openBrand:true
          })
        })
      }

      close = () => {
        this.setState({
          open:false,
          openBrand:false
        },()=>{this.load()})
      }

      message = (status, message) => {
        if (status === 'success'){
          toast.success(message, toastParams);
        } else {
          toast.error(message, toastParams);
        }
      }

      openEdit = (org) => {
        this.setState({
          open:true,
          org:org
        })
      }

      openBrandEdit = (brand, org) => {
        this.setState({
          open:false,
          openBrand:true,
          org:org,
          brand:brand
        })
      }


      render(){
        const {open, openBrand, orgList, org, brand} = this.state;
        return (
        <>
          <Slide open={open} component={<OrgForm handler={this.close} messageHandler={this.message} organization={org}/>} panelTitle={'Organization Information'}/>
          {org ?
          <Slide open={openBrand} component={<BrandForm handler={this.close} messageHandler={this.message} brand={brand} organization={org} />} panelTitle={'Brand Information'}/>
          : null}
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="sm:flex sm:items-center m-5">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Organizations</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all your Organizations
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {this.props.UserStore.is_admin ?
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                onClick={this.openOrg}
              >
                Add Organization
              </button>
             : null}
            </div>
          </div>
          {orgList.length > 0 ?
            orgList.map((org, index)=>
              <div key={index} className="overflow-hidden rounded-lg border border-gray-200 shadow-md m-5">
                <div className="sm:flex sm:items-center m-5">
                  <div className="sm:flex-auto">
                    <h2 className="text-xl font-semibold text-gray-900">{org.name}</h2>
                    <p className="mt-2 text-sm text-gray-700">
                      A list of all your Brands
                    </p>
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <div className="inline-flex rounded-sm shadow-sm">
                      <a href={"/ocon/" + org.hash_id}
                        className="py-1 px-2 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-lg first:ml-0 last:rounded-r-lg border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                        Interact
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                        </svg>
                      </a>
                      <a href={"/orev/" + org.hash_id}
                        className="py-1 px-2 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-lg first:ml-0 last:rounded-r-lg border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                        Reviews
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                        </svg>
                      </a>
                      <a href={"/ojob/" + org.hash_id}
                        className="py-1 px-2 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-lg first:ml-0 last:rounded-r-lg border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                        Jobs
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z" />
                        </svg>
                      </a>
                      {this.props.UserStore.is_admin === 1 ?
                      <button type="button" className="py-1 px-2 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-lg first:ml-0 last:rounded-r-lg border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto" onClick={() => {this.openBrandNew(org)}}>
                        Add Brand
                      </button>
                       : null}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                      <div className="overflow-hidden">
                        <table className="w-full border-collapse bg-white text-left text-sm text-gray-500 table-auto">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="px-6 py-4 font-medium text-gray-900">Name</th>
                              <th scope="col" className="px-6 py-4 font-medium text-gray-900 hidden md:table-cell">Description</th>
                              {this.props.UserStore.is_admin === 1 ?
                              <th scope="col" className="px-6 py-4 font-medium text-gray-900 hidden md:table-cell">Sources</th>
                              : null}
                              <th scope="col" className="px-6 py-4 font-medium text-gray-900">Interact</th>
                              {this.props.UserStore.is_admin === 1 ?
                              <th scope="col" className="px-6 py-4 font-medium text-gray-900 hidden md:table-cell">Actions</th>
                              : null}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                          {org.brand_list.length > 0 ?
                            org.brand_list.map((brand, bindex)=>
                              <BrandRow brand={brand} org={org} key={brand.hash_id} openHandler={this.openBrandEdit} mapHandler={this.map} is_admin={this.props.UserStore.is_admin}/>
                            )
                          : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          : null}
        </>
        )
      }
    }
  )
)

export default Orgs;


// <th scope="col" className="px-6 py-4 font-medium text-gray-900">Locations</th>
