import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionGet, connectionPost } from '../../utils/connection';
import { useParams } from "react-router-dom";
import Slide from './slide';
import BrandReviewSourceForm from '../forms/brand.review.source.form';
import BrandReviewSourceRow from './brand/brand.review.source.row';
import ScraperUtilForm from '../forms/scraperutil.form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const AllReviewSources = inject("UserStore")(
  observer(
    class AllReviewSourceList extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          source:null,
          sourceList:[],
        }
    	}

      componentDidMount(){
        this.load();
      }

      load = () => {
        connectionGet('review-sources-all', {}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              sourceList:res.data.review_source_list
            })
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      create_job = (review_source_id) => {
        if (review_source_id !== ''){
          connectionPost('scraper-util', {action:'create_job', review_source_id:review_source_id}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              this.message('success', res.data.message.message);
            } else {
              this.message('error', res.data.message.message);
            }
            this.load();
          })
        }
      }

      check_job = (review_source) => {
        console.log(review_source);
        if (review_source.last_queue){
          connectionPost('scraper-util', {action:'check_job', review_source_id:review_source.id, job_id:review_source.last_queue.job_id}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              this.message('success', res.data.message.message);
            } else {
              this.message('error', res.data.message.message);
            }
            this.load();
          })
        }
      }

      scrape_job = (review_source) => {
        console.log(review_source);
        if (review_source.last_queue){
          connectionPost('scraper-util', {action:'scrape_job', job_id:review_source.last_queue.job_id}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              this.message('success', res.data.message.message);
            } else {
              this.message('error', res.data.message.message);
            }
            this.load();
          })
        }
      }

      message = (status, message) => {
        if (status === 'success'){
          toast.success(message, toastParams);
        } else {
          toast.error(message, toastParams);
        }
      }

      render(){
        const {sourceList, source} = this.state;
        return (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="sm:flex sm:items-center m-5">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">All Sources</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all Review Sources.
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            </div>
          </div>
          <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md m-5">
            <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Name</th>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">URL</th>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Scrape Info</th>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100 border-t border-gray-100">
              {sourceList.length > 0 ?
                sourceList.map((source, index)=>
                  <BrandReviewSourceRow source={source} key={index} openHandler={this.openEdit} checkJob={this.check_job} createJob={this.create_job} scrapeJob={this.scrape_job}/>
                )
              : null}
              </tbody>
            </table>
          </div>
        </>
      );
      }
    }
  )
)

export default withParams(AllReviewSources);
