import React, { Component } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionGet } from '../../utils/connection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});


const Login = inject("UserStore")(
  observer(
    class LoginForm extends Component {
    	constructor(props) {
    	    super(props);
    	    this.state = {
            email: '',
            password: '',
            password_new: '',
            password_new_confirm: '',
            error: null,
            forgottenPassword:false,
            resetPassword:false
          };
    	}

      componentDidMount(){
        console.log(this.props.UserStore);
        connectionGet('test', {}, {})
        .then((res)=>{
          console.log(res.data);
        });
      }

      onSubmit = (event) => {
      	event.preventDefault();

        const { email, password, password_new_confirm, password_new, forgottenPassword, resetPassword} = this.state;

      	const { history,} = this.props;
        if (!forgottenPassword && !resetPassword){
          this.props.UserStore.get_token(email, password).then((res) => {
            console.log(res);
            if (!res || (res.response && res.response.status === 401)){
              toast.error("Authenication Error - Incorrect Username/Password, Password has Expired, Account is no longer active", toastParams);
            }
          });
        } else {
          if (resetPassword){
            // reset password
            // check that new password and confirm are the same
            if (password_new === password_new_confirm){
              this.props.UserStore.resetPassword(email, password, password_new, password_new_confirm).then((res) => {
                if (res.response && res.response.status !== 401){
                  toast.success('Password Updated', toastParams);
                  this.setState({forgottenPassword:!forgottenPassword});
                } else {
                  toast.error(res.data.message, toastParams);
                }
              });
            } else {
              toast.error('Passwords must match', toastParams);
            }
          } else {
            // reset password
            toast.error('This function is not enabled', toastParams);
            // this.props.userStore.resetPassword(email).then((res) => {
            //   if (res.data.errors === 0){
            //     toast.success(res.data.message, toastParams);
            //     this.setState({forgottenPassword:!forgottenPassword});
            //   } else {
            //     toast.error(res.data.message, toastParams);
            //   }
            // });
          }
        }
    	}


      render(){
        const {email,password, error, forgottenPassword} = this.state;
        const isInvalid = email === '';

        return (
          <div className="bg-no-repeat bg-cover bg-center relative" style={{backgroundImage:`url(${process.env.PUBLIC_URL + 'jon-tyson-vVSleEYPSGY-unsplash.jpg'})`}}>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              pauseOnVisibilityChange={false}
              draggable={false}
              pauseOnHover={false}
            />

            <div className="md:absolute md:bg-gradient-to-b from-cyan-500 to-blue-400 opacity-75 inset-0 z-0"></div>
            <div className="min-h-screen sm:flex sm:flex-row mx-0 justify-center">
                <div className="flex-col flex  self-center p-10 sm:max-w-5xl xl:max-w-2xl  z-10">
                  <div className="self-start hidden lg:flex flex-col  text-white">
                    <img src="" className="mb-3"/>
                    <h1 className="mb-3 font-bold text-5xl">Welcome Back</h1>
                    <p className="pr-3">Lorem ipsum is placeholder text commonly used in the graphic, print,
                      and publishing industries for previewing layouts and visual mockups</p>
                  </div>
                </div>
                <div className="flex justify-center self-center  z-10">
                  <div className="p-12 bg-white mx-auto rounded-2xl w-100 ">
                    <form onSubmit={this.onSubmit}>
                      <div className="mb-4">
                        <h3 className="font-semibold text-2xl text-gray-800">Sign In </h3>
                        <p className="text-gray-500">Please sign in to your account.</p>
                      </div>
                      <div className="space-y-5">
                        <div className="space-y-2">
                          <label className="text-sm font-medium text-gray-700 tracking-wide">Email</label>
                          <input className=" w-full text-base px-4 py-2 border  border-gray-300 rounded-lg focus:outline-none focus:border-green-400" type="" placeholder="mail@gmail.com" value={email} onChange={event => this.setState(byPropKey('email', event.target.value))}/>
                        </div>
                        <div className="space-y-2">
                          <label className="mb-5 text-sm font-medium text-gray-700 tracking-wide">Password</label>
                          <input className="w-full content-center text-base px-4 py-2 border  border-gray-300 rounded-lg focus:outline-none focus:border-green-400" type="password" placeholder="Enter your password" value={password} onChange={event => this.setState(byPropKey('password', event.target.value))}/>
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <input id="remember_me" name="remember_me" type="checkbox" className="h-4 w-4 bg-blue-500 focus:ring-blue-400 border-gray-300 rounded"/>
                            <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-800">Remember me</label>
                          </div>
                          <div className="text-sm">
                            <a href="#" className="text-green-400 hover:text-green-500">Forgot your password?</a>
                          </div>
                        </div>
                        <div>
                          <button type="submit" className="w-full flex justify-center bg-green-400  hover:bg-green-500 text-gray-100 p-3  rounded-full tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500">Sign in</button>
                        </div>
                      </div>
                      <div className="pt-5 text-center text-gray-400 text-xs">
                        <span>
                          Copyright © 2023-2024
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
          </div>
        );
      }
    }
  )
)
export default Login;
