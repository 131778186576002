import React, {Component} from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom'
import { Provider, observer } from 'mobx-react';
import UserStore from './store/userStore';
import { isHydrated } from 'mobx-persist-store';
import Layout from './components/common/layout';
import Login from './components/common/login';

// function App() {
//   return (
//     <LoginForm/>
//   );
// }
const stores = { UserStore };

const App = observer((
  class MyApp extends Component{
    render(){
      if (stores.UserStore.token !== ''){
        return (
          <Provider {...stores}>
            <Layout/>
          </Provider>
        );
      } else {
        return (
          <Provider {...stores}>
            <Router>
              <Switch>
                <Route path='/' element={ <Login/> } />
              </Switch>
            </Router>
          </Provider>
        );
      }
    }
  }
));

export default App;
