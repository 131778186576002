import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionGet, connectionPost } from '../../utils/connection';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import JobRow  from './brand/job.row';
import 'react-toastify/dist/ReactToastify.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const OrgJobList = inject("UserStore")(
  observer(
    class OrgJobList1 extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          organization:null,
          job_list:[]
        }
    	}

      componentDidMount(){
        this.load();
      }

      load = () => {
        connectionGet('organizations/' +this.props.params.organization_hash_id, {job:1}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              organization:res.data.organization,
              job_list:res.data.job_list
            })
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      run_job = (job_id) => {
        connectionPost('scraper-util', {action:'run_job', job_id:job_id}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            toast.success(res.data.message, toastParams);
          } else {
            toast.error(res.data.message, toastParams);
          }
        })
      }

      render(){
        const {organization, job_list} = this.state;
        if (organization === null) return <div>Loading</div>;
        return (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="sm:flex sm:items-center m-5">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">{organization.name}</h1>
              <p className="mt-2 text-sm text-gray-700">
                Current Jobs
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <a href={"/ojob/" + organization.hash_id}
                className="py-3 px-4 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-lg first:ml-0 last:rounded-r-lg border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                Add Job
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z" />
                </svg>
              </a>

            </div>
          </div>
          <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md m-5">
            <table className="w-full border-collapse bg-white text-left text-sm text-gray-500 table-auto">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Description</th>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900 text-right">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100 border-t border-gray-100">
              {job_list.length > 0 ?
                job_list.map((job, index)=>
                  <JobRow job={job} org_hash_id={organization.hash_id} jobHandler={this.run_job} key={index}/>
                )
              : null}
              </tbody>
            </table>
          </div>
        </>
        )
      }
    }
  )
)

export default withParams(OrgJobList);


// <th scope="col" className="px-6 py-4 font-medium text-gray-900">Locations</th>
// <DatePicker
//   className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
//   selected={start_date}
//   onChange={this.setStart}
//   dateFormat="yyyy-MM-dd"
// />
// , [0,'Custom Range']
// <select className="py-3 px-4 pr-9 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500" onChange={this.setDateRange}>
// {date_list.map((range, index) =>
//   <option key={index} value={range[0]}>{range[1]}</option>
// )}
// </select>
