import React, { Component, Fragment } from 'react';
import { useParams } from "react-router-dom";
import { inject, observer } from 'mobx-react'
import { connectionGet, connectionPost } from '../../utils/connection';
import { ToastContainer, toast } from 'react-toastify';
import HeatMap from '@uiw/react-heat-map';
import 'react-toastify/dist/ReactToastify.css';

// const value = [
//   { date: '2016/01/11', count: 2 },
//   { date: '2016/01/12', count: 20 },
//   { date: '2016/01/13', count: 10 },
//   ...[...Array(17)].map((_, idx) => ({ date: `2016/02/${idx + 10}`, count: idx, content: '' })),
//   { date: '2016/04/11', count: 2 },
//   { date: '2016/05/01', count: 5 },
//   { date: '2016/05/02', count: 5 },
//   { date: '2016/05/04', count: 11 },
// ];

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const BrandHeatMap = inject("UserStore")(
  observer(
    class BrandHeatMap1 extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          brand:null,
          d_neg_values:null,
          d_pos_values:null
        }
    	}

      componentDidMount(){
        this.load();
        this.loadHeatMap();
      }

      load = () => {
        connectionGet('brand/'  + this.props.params.organization_hash_id + '/' + this.props.params.brand_hash_id, {}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              brand:res.data.brand
            })
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      loadHeatMap = () => {
        connectionPost('heat-map/'  + this.props.params.brand_hash_id, {}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              d_neg_values:res.data.neg_results,
              d_pos_values:res.data.pos_results
            })
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      render(){
        const {brand, d_neg_values, d_pos_values} = this.state;
        if (!brand) return <div>Loading</div>;
        return (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="sm:flex sm:items-center m-5">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Heat Map: {brand.name}</h1>
              <p className="mt-2 text-sm text-gray-700">
                Heat Map of Classified Reviews
              </p>
            </div>
          </div>
          <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md m-5">
            <div className="flex flex-col">
              <div className="p-5">
                <h2>Negative Sentiment</h2>
            {d_neg_values ? Object.entries(d_neg_values).map(([key, value], i) => {
              return (
                <div className="overflow-x-auto">
                <h3>{key}</h3>
                <HeatMap key={i} width={1000} style={{ color: '#ad001d' }} panelColors={{
        0: '#f4decd',
        2: '#e4b293',
        4: '#d48462',
        10: '#c2533a',
        20: '#ad001d',
        30: '#000',
      }} value={value} startDate={new Date(value[0].date)} endDate={new Date()} />
                </div>
              );
            }) : null}
              </div>
              <div className="p-5">
                <h2>Positive Sentiment</h2>
            {d_pos_values ? Object.entries(d_pos_values).map(([key, value], i) => {
              return (
                <div className="overflow-x-auto">
                <h3>{key}</h3>
                <HeatMap key={i} width={1000} value={value} startDate={new Date(value[0].date)} endDate={new Date()} />
                </div>
              );
            }) : null}
              </div>
            </div>
          </div>
        </>
        )
      }
    }
  )
)

export default withParams(BrandHeatMap);


// <th scope="col" className="px-6 py-4 font-medium text-gray-900">Locations</th>
