import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import ReviewCard from './reviewcard';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

class Prompt{
  prompt = '';
  prompt_type = 'QUESTION';
}

class Job{
  id = 0;
  brand_id = 0;
  sys_schedule_id = 1;
  sentiment = 0;
  prompt_list = [];
  description = '';
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const OrgJob = inject("UserStore")(
  observer(
    class OrgJob1 extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          organization:null,
          sys_schedule_list:[],
          job:new Job(),
          prompt_text:''
        }
    	}

      componentDidMount(){
        this.load();
      }

      handleInputChange = (e) => {
        let updated_obj = Object.assign({}, this.state.job);
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        if (e.target.name === 'sentiment' && e.target.value){
          value = Number(value);
        }
        updated_obj[e.target.name] = value;
        this.setState({job:updated_obj},()=>{console.log(this.state.job)});
      }

      handlePromptChange = (e) => {
        this.setState({
          prompt_text:e.target.value
        })
      }

      addPrompt = (type) => {
        let updated_obj = Object.assign({}, this.state.job);
        let prompt = new Prompt();
        if (this.state.prompt_text !== ''){
          prompt.prompt_type = type;
          prompt.prompt = this.state.prompt_text;
          updated_obj.prompt_list.push(prompt);
          this.setState({
            job:updated_obj,
            prompt_text:''
          },()=>{console.log(this.state.job)});
        } else {
          alert('You must enter a prompt query');
        }
      }

      removePrompt = (prompt) => {
        let updated_obj = this.state.job;
        if (updated_obj.prompt_list.length > 0){
          let newPrompList = updated_obj.prompt_list.filter(p => p.prompt !== prompt.prompt);
          updated_obj.prompt_list = newPrompList;
          this.setState({
            job:updated_obj
          })
        }
      }

      saveJob = () => {
        if (this.state.job.id === 0){
          connectionPost('job/' + this.state.organization.hash_id , {job:this.state.job}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              toast.success(res.data.message, toastParams);
            }
          })
          .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
        } else {
          connectionPut('job/' + this.state.organization.hash_id , {job:this.state.job}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              toast.success(res.data.message, toastParams);
            }
          })
          .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
        }
      }


      load = () => {
        let connStr = 'organizations/' +this.props.params.organization_hash_id;
        connectionGet(connStr, {job_hash:this.props.params.job_hash_id}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            if (res.data.job){
              this.setState({
                organization:res.data.organization,
                sys_schedule_list:res.data.sys_schedule_list,
                job:res.data.job.config_json
              })
            } else {
              this.setState({
                organization:res.data.organization,
                sys_schedule_list:res.data.sys_schedule_list
              })
            }
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      render(){
        const {organization, job, sys_schedule_list, query, summary, docs, loadingSummary, loadingQuestion, sentiment, start_date, end_date, date_list, prompt_text} = this.state;
        if (organization === null) return <div>Loading</div>;
        return (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="sm:flex sm:items-center m-5">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">{organization.name}</h1>
              <p className="mt-2 text-sm text-gray-700">
                Create a new Job.  Jobs run automatically and email results to the organization owner account.
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <a href={"/ojob-list/" + organization.hash_id}
                className="py-3 px-4 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-lg first:ml-0 last:rounded-r-lg border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                See Job List
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z" />
                </svg>
              </a>

            </div>
          </div>
          <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md m-5">
            <form className="space-y-8 divide-y divide-gray-200">
              <div className="space-y-8 divide-y divide-gray-200">
                <div className="lg:pt-8">
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-12 px-5 lg:px-20">
                      <label htmlFor="brand_id" className="block text-sm font-medium text-gray-700">
                        Description
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="description"
                          id="description"
                          value={job.description}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-12 px-5 lg:px-20">
                      <label htmlFor="brand_id" className="block text-sm font-medium text-gray-700">
                        Select Brand
                      </label>
                      <div className="mt-1">
                        <div className="inline-block relative w-64">
                          <select
                          name="brand_id"
                          id="brand_id"
                          value={job.brand_id}
                          onChange={this.handleInputChange}
                          className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                            <option>Select</option>
                          {organization.brand_list ? organization.brand_list.map((brand, i) => {
                            return (<option value={brand.id} key={i}>{brand.name}</option>);
                          }) : <option>Loading...</option>}
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-12 px-5 lg:px-20">
                      <label htmlFor="sys_schedule_id" className="block text-sm font-medium text-gray-700">
                        Select Schedule
                      </label>
                      <div className="mt-1">
                        <div className="inline-block relative w-64">
                          <select
                          name="sys_schedule_id"
                          id="sys_schedule_id"
                          value={job.sys_schedule_id}
                          onChange={this.handleInputChange}
                          className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                          {sys_schedule_list ? sys_schedule_list.map((schedule, i) => {
                            return (<option value={schedule.id} key={i}>{schedule.name}</option>);
                          }) : <option>Loading...</option>}
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-12 px-5 lg:px-20">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                        Select Sentiment
                      </label>
                      <div className="mt-1">
                        <div className="flex">
                          <input type="radio" name="sentiment" value={0} onChange={this.handleInputChange} className="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 pointer-events-none focus:ring-blue-500" id="sentiment-negative" checked={job.sentiment === 0}/>
                          <label htmlFor="sentiment-negative" className="text-sm text-gray-500 ml-2">Negative</label>
                        </div>
                        <div className="flex">
                          <input type="radio" name="sentiment" id="sentiment-positive" value={1} onChange={this.handleInputChange} className="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 pointer-events-none focus:ring-blue-500 " checked = {job.sentiment === 1}/>
                          <label htmlFor="sentiment-positive" className="text-sm text-gray-500 ml-2">Positive</label>
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-12 px-5 lg:px-20 mb-1">
                      <div className="mt-1 mb-4">
                        <textarea className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500" rows="3" placeholder="ask me a question or give me a topic to summarize..." name="query" id="query" value={prompt_text} onChange={this.handlePromptChange}></textarea>
                      </div>
                      <div className="inline-flex shadow-sm">
                        <button type="button" className="py-1 px-2 inline-flex justify-center items-center gap-2 first:rounded-l-md first:ml-0 last:rounded-r-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all text-sm dark:focus:ring-offset-gray-800" onClick={()=>this.addPrompt('SUMMARY')}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                          </svg>
                          <span className="sm:text-xs md:text-sm">Summary</span>
                        </button>
                        <button type="button" className="xs:min-w-full py-2 px-3 inline-flex justify-center items-center gap-2 sm:first:rounded-l-lg first:ml-0 last:rounded-r-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800 text-sm" onClick={()=>this.addPrompt('QUESTION')}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                          </svg>
                          <span className="sm:text-xs md:text-sm">Question</span>
                        </button>
                      </div>
                    </div>
                    <div className="sm:col-span-12 px-5 lg:px-20 mb-10">
                      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                          <div className="overflow-hidden">
                            <table className="w-full border-collapse bg-white text-left text-sm text-gray-500 table-auto">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Type</th>
                                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Prompt</th>
                                  <th scope="col" className="px-6 py-4 font-medium text-gray-900 hidden md:table-cell">Actions</th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                                {job.prompt_list ? job.prompt_list.map((prompt, i)=>
                                  <tr key={i}>
                                    <td className="xs:whitespace-nowrap px-6 py-4 hidden md:table-cell">
                                      <div className="text-sm">
                                        <div className="font-medium text-gray-700">{prompt.prompt_type}</div>
                                      </div>
                                    </td>
                                    <td className="xs:whitespace-nowrap px-6 py-4 hidden md:table-cell">
                                      <div className="text-sm">
                                        <div className="font-medium text-gray-700">{prompt.prompt}</div>
                                      </div>
                                    </td>
                                    <td className="xs:whitespace-nowrap px-6 py-4 hidden md:table-cell">
                                      <a x-data="{ tooltip: 'Delete' }" href="#" onClick={()=>this.removePrompt(prompt)}>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          stroke="currentColor"
                                          className="h-6 w-6"
                                          x-tooltip="tooltip"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                          />
                                        </svg>
                                      </a>
                                    </td>
                                  </tr>
                                ) :
                                <tr>
                                  <td colSpan="3">No Prompts</td>
                                </tr>
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-12 px-5 lg:px-20 mb-10">
                      <button type="button" className="xs:min-w-full py-2 px-3 inline-flex justify-center items-center gap-2 sm:first:rounded-l-lg first:ml-0 last:rounded-r-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800 text-sm" onClick={this.saveJob}>
                        <span className="sm:text-xs md:text-sm">Save Job</span>
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
        )
      }
    }
  )
)

export default withParams(OrgJob);


// <th scope="col" className="px-6 py-4 font-medium text-gray-900">Locations</th>
// <DatePicker
//   className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
//   selected={start_date}
//   onChange={this.setStart}
//   dateFormat="yyyy-MM-dd"
// />
// , [0,'Custom Range']
// <select className="py-3 px-4 pr-9 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500" onChange={this.setDateRange}>
// {date_list.map((range, index) =>
//   <option key={index} value={range[0]}>{range[1]}</option>
// )}
// </select>
