import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionGet, connectionPost } from '../../utils/connection';
import { useParams } from "react-router-dom";
import Slide from './slide';
import BrandReviewSourceForm from '../forms/brand.review.source.form';
import BrandReviewSourceRow from './brand/brand.review.source.row';
import ScraperUtilForm from '../forms/scraperutil.form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const BrandReviewSources = inject("UserStore")(
  observer(
    class BrandReviewSourceList extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          open:false,
          openUtil:false,
          brand:null,
          source:null,
          sourceList:[],
          sysSourceList:[],
          selectedValue:null
        }
    	}

      componentDidMount(){
        this.load();
      }

      load = () => {
        connectionGet('brand/'  + this.props.params.brand_hash_id + '/review-source', {}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              sourceList:res.data.review_source_list,
              brand:res.data.brand,
              sysSourceList:res.data.sys_review_source_list
            })
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      save = () => {
        console.log('hello')
        connectionPost('brand/'  + this.props.params.brand_hash_id + '/review-source', {source:this.state.selectedValue}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              sourceList:res.data.review_source_list
            })
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      create_job = (review_source_id) => {
        if (review_source_id !== ''){
          connectionPost('scraper-util', {action:'create_job', review_source_id:review_source_id}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              this.message('success', res.data.message.message);
            } else {
              this.message('error', res.data.message.message);
            }
            this.load();
          })
        }
      }

      check_job = (review_source) => {
        console.log(review_source);
        if (review_source.last_queue){
          connectionPost('scraper-util', {action:'check_job', review_source_id:review_source.id, job_id:review_source.last_queue.job_id}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              this.message('success', res.data.message.message);
            } else {
              this.message('error', res.data.message.message);
            }
            this.load();
          })
        }
      }

      scrape_job = (review_source) => {
        console.log(review_source);
        if (review_source.last_queue){
          connectionPost('scraper-util', {action:'scrape_job', job_id:review_source.last_queue.job_id}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              this.message('success', res.data.message.message);
            } else {
              this.message('error', res.data.message.message);
            }
            this.load();
          })
        }
      }

      toggle = () => {
        this.setState({
          open:true,
          source:{id:null, sys_review_source_id:'', url:''}
        })
      }

      close = () => {
        this.setState({
          open:false
        }, ()=>{this.load()})
      }

      openUtil = () => {
        this.setState({
          open:false,
          openUtil:true
        })
      }

      closeUtil = () => {
        this.setState({
          openUtil:false
        })
      }

      message = (status, message) => {
        if (status === 'success'){
          toast.success(message, toastParams);
        } else {
          toast.error(message, toastParams);
        }
      }

      openEdit = (source) => {
        this.setState({
          open:true,
          source:source
        })
      }

      render(){
        const {open, openUtil, sourceList, source, brand, selectedValue, sysSourceList} = this.state;
        if (brand === null) {
          return (
            <span>Loading</span>
          )
        } else {
        return (
        <>
          <Slide open={open} component={<BrandReviewSourceForm handler={this.close} messageHandler={this.message} brand={brand} source={source} sysSourceList={sysSourceList}/>} panelTitle={'Brand Source Information'}/>
          <Slide open={openUtil} component={<ScraperUtilForm handler={this.closeUtil} brand={brand} messageHandler={this.message}/>} panelTitle={'Scraper Utility'}/>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="sm:flex sm:items-center m-5">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">{brand.name} Sources</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all {brand.name}'s Review Sources.
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <div className="inline-flex">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  onClick={this.toggle}
                >
                  Add Source
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  onClick={this.openUtil}
                >
                  Open Utils
                </button>
              </div>
            </div>
          </div>
          <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md m-5">
            <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Name</th>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">URL</th>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Scrape Info</th>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100 border-t border-gray-100">
              {sourceList.length > 0 ?
                sourceList.map((source, index)=>
                  <BrandReviewSourceRow source={source} key={index} openHandler={this.openEdit} checkJob={this.check_job} createJob={this.create_job} scrapeJob={this.scrape_job}/>
                )
              : null}
              </tbody>
            </table>
          </div>
        </>
      );
        }
      }
    }
  )
)

export default withParams(BrandReviewSources);
