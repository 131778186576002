import React, { Component, Fragment, useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionPost, connectionPut } from '../../utils/connection';
import AutoCompleteComponent from './components/AutoComplete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});


const BrandLocationForm = inject("UserStore")(
  observer(
    class BrandLocationForm1 extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          brand:this.props.brand,
          location:{id:null, name:'', description:''},
          value:null
        }
    	}

      componentDidMount(){
        if (this.props.location !== null){
          this.setState({
            location:this.props.location,
            brand:this.props.brand
          })
        }
      }

      componentWillReceiveProps(nextProps){
        console.log(nextProps)
        this.setState({
          location:nextProps.location,
          brand:nextProps.brand
        })
      }

      handleInputChange = (e) => {
        let updated_obj = Object.assign({}, this.state.location);
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        updated_obj[e.target.name] = value;
        this.setState({location:updated_obj});
      }

      handleSelect = (value) => {
        console.log(value);
      }

      handleSubmit = (e) => {
        e.preventDefault();

        let location = this.state.location;

        let brand = this.state.brand;

        if (location.id){
          connectionPut('location/' + brand.hash_id + '/' + location.hash_id, {location:location}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              // alert(res.data.message)
              this.props.messageHandler('success', res.data.message);
              this.props.handler();
              // toast.success(res.data.message, toastParams);
            } else {
              this.props.messageHandler('error', res.data.message);
              // toast.error(res.data.message, toastParams);
            }
          })
          // .catch((error)=>{toast.error(error.response.statusText, toastParams);});
        } else {
          connectionPost('location/' + brand.hash_id, {location:location}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              this.setState({location:res.data.location},()=>{
                this.props.messageHandler('success', res.data.message);
                this.props.handler();
              });
            } else {
              this.props.messageHandler('error', res.data.message);
            }
          })
          // .catch((error)=>{toast.error(error.response.statusText, toastParams);});
        }
      }

      render(){
        const {location, brand, value} = this.state;
        if (location === null){
           return (<span>Loading</span>);
        } else {
          return (
            <form className="space-y-8 divide-y divide-gray-200" onSubmit={this.handleSubmit}>
              <div className="space-y-8 divide-y divide-gray-200">
                <div className="pt-8">
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                        Name
                      </label>
                      <div className="mt-1">
                        <AutoCompleteComponent handleSelect={this.handleSelect}/>
                      </div>
                    </div>
                    <div className="sm:col-span-6">
                      <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                        Description
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="description"
                          name="description"
                          value={location.description}
                          rows={3}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <p className="mt-2 text-sm text-gray-500">Description of BrandLocation.</p>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-end">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }
      }
    }
  )
)

export default BrandLocationForm;
