import React, { Component, useState, useEffect } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const AutoCompleteComponent = (props) => {

  const [value, setValue] = useState(null);

  useEffect(() => props.handleSelect(value), [value]);

  return (
    <GooglePlacesAutocomplete
      autocompletionRequest={{
        componentRestrictions: {
          country: ["us"],
        },
      }}
      minLengthAutocomplete={3}
      apiKey="AIzaSyDM9Ij1SlexiEnHKxzV6imUY1gaMnO_qrk"
      selectProps={{
        value,
        onChange: setValue,
      }}
    />
  );
}

export default AutoCompleteComponent
