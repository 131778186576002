import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionPost, connectionPut } from '../../utils/connection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const BrandForm = inject("UserStore")(
  observer(
    class BrandForm1 extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          brand:{id:null, name:'', description:''}
        }
    	}

      componentDidMount(){
        if (this.props.brand !== null){
          this.setState({
            brand:this.props.brand,
            org:this.props.organization
          })
        } else {
          this.setState({
            org:this.props.organization
          },()=>{console.log(this.state.org)})
        }
      }

      componentWillReceiveProps(nextProps){
        this.setState({
          brand:nextProps.brand,
          org:nextProps.organization
        })
      }

      handleInputChange = (e) => {
        let updated_obj = Object.assign({}, this.state.brand);
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        updated_obj[e.target.name] = value;
        this.setState({brand:updated_obj});
      }

      handleSubmit = (e) => {
        e.preventDefault();
        let brand = this.state.brand;
        let organization = this.state.org;
        console.log(organization)
        if (brand.id){
          connectionPut('brand/' + organization.hash_id + '/' + brand.hash_id, {brand:brand}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              // alert(res.data.message)
              this.props.messageHandler('success', res.data.message);
              this.props.handler();
              // toast.success(res.data.message, toastParams);
            } else {
              this.props.messageHandler('error', res.data.message);
              // toast.error(res.data.message, toastParams);
            }
          })
          // .catch((error)=>{toast.error(error.response.statusText, toastParams);});
        } else {
          connectionPost('brand/' + organization.hash_id, {brand:brand}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              this.props.messageHandler('success', res.data.message);
              this.props.handler();
            } else {
              this.props.messageHandler('error', res.data.message);
            }
          })
          // .catch((error)=>{toast.error(error.response.statusText, toastParams);});
        }
      }

      render(){
        const {brand} = this.state;
        if (!brand) return "Brand not set";
        return (
          <form className="space-y-8 divide-y divide-gray-200" onSubmit={this.handleSubmit}>
            <div className="space-y-8 divide-y divide-gray-200">
              <div className="pt-8">
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                      Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={brand.name}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                      Description
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="description"
                        name="description"
                        value={brand.description}
                        rows={3}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">Description of Brand.</p>
                  </div>
                  <div className="sm:col-span-6">
                    <label htmlFor="Avatar URL" className="block text-sm font-medium text-gray-700">
                      Avatar URL
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="avatar_url"
                        id="avatar_url"
                        value={brand.avatar_url}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        );
      }
    }
  )
)

export default BrandForm;
