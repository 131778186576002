import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionPost, connectionPut, connectionGet } from '../../utils/connection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const ScraperUtilForm = inject("UserStore")(
  observer(
    class ScraperUtilForm1 extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          review_source_id:"",
          back_date:180,
          job_id:"",
          brand:null
        }
    	}

      componentDidMount(){
        this.setState({
          brand:this.props.brand
        })
      }

      componentWillReceiveProps(nextProps){
        this.setState({
          brand:nextProps.brand
        })
      }

      handleInputChange = (e) => {
        let value = e.target.value;
        let target_name = e.target.name;
        this.setState({[target_name]:value},()=>{console.log(this.state.review_source_id)});
      }

      create_job = (e) => {
        e.preventDefault();
        if (this.state.review_source_id !== ''){
          connectionPost('scraper-util', {action:'create_job', review_source_id:this.state.review_source_id, back_date:this.state.back_date}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              this.props.messageHandler('success', res.data.message);
            } else {
              this.props.messageHandler('error', res.data.message);
            }
          })
        }
      }

      check_job = (e) => {
        e.preventDefault();
        console.log(this.state.review_source_id)
        if (this.state.review_source_id !== ''){
          connectionPost('scraper-util', {action:'check_job', review_source_id:this.state.review_source_id, job_id:this.state.job_id}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              this.props.messageHandler('success', res.data.message);
            } else {
              this.props.messageHandler('error', res.data.message);
            }
          })
        }
      }

      scrape_job = (e) => {
        e.preventDefault();
        if (this.state.job_id !== ''){
          connectionPost('scraper-util', {action:'scrape_job', job_id:this.state.job_id}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              this.props.messageHandler('success', res.data.message);
            } else {
              this.props.messageHandler('error', res.data.message);
            }
          })
        }
      }
      review_classify = (e) => {
        e.preventDefault();
        if (this.state.brand !== null){
          connectionPost('scraper-util', {action:'classify_reviews', brand_id:this.state.brand.id}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              this.props.messageHandler('success', res.data.message);
            } else {
              this.props.messageHandler('error', res.data.message);
            }
          })
        }
      }

      run_job = (e) => {
        e.preventDefault();
        connectionPost('scraper-util', {action:'run_job', job_id:this.state.job_id}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.props.messageHandler('success', res.data.message);
          } else {
            this.props.messageHandler('error', res.data.message);
          }
        })
      }

      send_email = (e) => {
        e.preventDefault();
        connectionPost('scraper-util', {action:'send_email'}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.props.messageHandler('success', res.data.message);
          } else {
            this.props.messageHandler('error', res.data.message);
          }
        })
      }

      send_job_email = (e) => {
        e.preventDefault();
        connectionPost('scraper-util', {action:'send_job_email', job_id:this.state.job_id}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.props.messageHandler('success', res.data.message);
          } else {
            this.props.messageHandler('error', res.data.message);
          }
        })
      }

      import_qr = (e) => {
        e.preventDefault();
        connectionPost('scraper-util', {action:'import_qr'}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.props.messageHandler('success', res.data.message);
          } else {
            this.props.messageHandler('error', res.data.message);
          }
        })
      }

      time_frame = (e) => {
        e.preventDefault();
        connectionPost('scraper-util', {action:'time_frame', job_id:this.state.job_id, back_date:this.state.back_date}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.props.messageHandler('success', res.data.message);
          } else {
            this.props.messageHandler('error', res.data.message);
          }
        })
      }

      keyphrase = (e) => {
        e.preventDefault();
        connectionPost('scraper-util', {action:'keyphrase', job_id:this.state.job_id}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.props.messageHandler('success', res.data.message);
          } else {
            this.props.messageHandler('error', res.data.message);
          }
        })
      }

      topics = (e) => {
        e.preventDefault();
        connectionPost('scraper-util', {action:'topics', job_id:this.state.job_id}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.props.messageHandler('success', res.data.message);
          } else {
            this.props.messageHandler('error', res.data.message);
          }
        })
      }


      render(){
        const {review_source_id, back_date, job_id, brand} = this.state;
        console.log(this.props)
        if (!brand) return (<div>Loading</div>);
        return (
          <form className="space-y-8 divide-y divide-gray-200" onSubmit={this.handleSubmit}>
            <div className="space-y-8 divide-y divide-gray-200">
              <div className="pt-8">
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                      Review Source ID
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="review_source_id"
                        id="review_source_id"
                        value={review_source_id}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                      Back Date
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="back_date"
                        id="back_date"
                        value={back_date}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                      Job Id
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="job_id"
                        id="job_id"
                        value={job_id}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={this.create_job}
                    >
                      Create Job
                    </button>
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={this.check_job}
                    >
                      Check Job
                    </button>
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={this.scrape_job}
                    >
                      Scrape Job
                    </button>
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={this.review_classify}
                    >
                      Classify Reviews
                    </button>
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={this.run_job}
                    >
                      Run Job
                    </button>
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={this.send_email}
                    >
                      Send Email
                    </button>
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={this.send_job_email}
                    >
                      Send Job Email
                    </button>
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={this.time_frame}
                    >
                      time test
                    </button>
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={this.import_qr}
                    >
                      import qr
                    </button>
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={this.keyphrase}
                    >
                      keyphrase
                    </button>
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={this.topics}
                    >
                      topics
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        );
      }
    }
  )
)

export default ScraperUtilForm;
