import React, { Component, Fragment } from 'react';
import { useParams } from "react-router-dom";
import { inject, observer } from 'mobx-react'
import { connectionGet, connectionPost } from '../../utils/connection';
import { ToastContainer, toast } from 'react-toastify';
import ReviewCard from './reviewcard';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';


let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

class Filter{
  brand_id_list = [];
  date_range = [1, 'Today'];
  start_date = new Date();
  end_date = new Date();
  aspect_custom_id = 0;
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const OrgReviews2 = inject("UserStore")(
  observer(
    class OrgReviews1 extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          organization:null,
          brand_list:[],
          filter:new Filter(),
          review_list:[],
          original_review_list:[],
          loading:false,
          date_list:[[7,'Last Week'],[30,'Last 30 Days'],[90,'Last 90 Days'],[365,'Last Year']],
          start_date: new Date().setMonth(new Date().getMonth()-3),
          end_date: new Date(),
          filter_score:0,
          filter_label:[]
        }
    	}

      componentDidMount(){
        this.load();
      }

      load = () => {
        connectionGet('organizations/'  + this.props.params.organization_hash_id, {brands:1}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              organization:res.data.organization,
              brand_list:res.data.brand_list
            })
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      fetchResults = () => {
        this.setState({
          loading:true
        }, ()=>{
          let updated_filter = Object.assign({}, this.state.filter);
          updated_filter.start_date = moment(this.state.start_date).format('MM/DD/YYYY');
          updated_filter.end_date = moment(this.state.end_date).format('MM/DD/YYYY');
          connectionPost('reviews', {filter:updated_filter}, {username: this.props.UserStore.token}
          ).then((res) => {
            if (res.data.errors === 0){
              this.setState({
                review_list:res.data.review_list,
                original_review_list:res.data.review_list,
                loading:false
              })
            }
          }).catch((error) => {
            this.setState({
              loading:false
            })
            return error;
          });
        });
      }

      setStart = (date) => {
        let updated_filter = Object.assign({}, this.state.filter);
        updated_filter.end_date = moment(this.state.end_date).format('MM/DD/YYYY');
        updated_filter.start_date = moment(date).format('MM/DD/YYYY');
        updated_filter.date_range = [0, 'Custom Range'];
        this.setState({
          selected_date: updated_filter.date_range,
          start_date: date,
          filter:updated_filter
        });
      }

      setEnd = (date) => {
        let updated_filter = Object.assign({}, this.state.filter);
        updated_filter.start_date = moment(this.state.start_date).format('MM/DD/YYYY');
        updated_filter.end_date = moment(date).format('MM/DD/YYYY');
        updated_filter.date_range = [0, 'Custom Range'];
        this.setState({
          selected_date: updated_filter.date_range,
          end_date: date,
          filter:updated_filter
        });
      }

      setDateRange = (e) => {
        let range = this.state.date_list.find(x => x[0] == parseInt(e.target.value));
        let updated_filter = Object.assign({}, this.state.filter);
        updated_filter.date_range = range;
        if (range[0] === 0){
          // set the start and end
          updated_filter.end_date = moment(this.state.end_date).format('MM/DD/YYYY');
          updated_filter.start_date = moment(this.state.start_date).format('MM/DD/YYYY');
        }
        this.setState({
          selected_date:range,
          filter:updated_filter
        })
      }

      filterScore = (e) => {
        let value = parseInt(e.target.value);
        var tmp_review_list = this.state.original_review_list.filter((review) =>
          review.sys_review_source.id !== 1 && review.rating_value === value
        );
        this.setState({
          filter_score:value,
          review_list:tmp_review_list
        });
      }

      filterLabel = (e) => {
        let value = e.target.value;
        let updated_obj = Object.assign([], this.state.filter_label);
        if (!updated_obj.includes(value)){
          updated_obj.push(value)
        } else {
          var index = updated_obj.indexOf(value);
          if (index !== -1) updated_obj.splice(index, 1);
        }
        var tmp_review_list = this.state.original_review_list.filter((review) =>
          review.classification_list_min && review.classification_list_min.includes(value)
        );
        this.setState({
          filter_label:updated_obj,
          review_list:tmp_review_list
        });
      }

      handleInputChange = (e) => {
        let updated_obj = Object.assign({}, this.state.filter);
        var brand_id = parseInt(e.target.value);
        if (e.target.checked && !updated_obj.brand_id_list.includes(brand_id)){
          updated_obj.brand_id_list.push(brand_id)
        } else {
          var index = updated_obj.brand_id_list.indexOf(brand_id);
          if (index !== -1) updated_obj.brand_id_list.splice(index, 1);
        }
        this.setState({filter:updated_obj});
      }

      render(){
        const {organization, brand_list, filter, review_list, original_review_list, start_date, end_date, date_list, filter_score, filter_label} = this.state;
        if (!organization) return <div>Loading</div>;
        return (
        <div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="sm:flex sm:items-center m-5">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">{organization.name}: Reviews</h1>
              <p className="mt-2 text-sm text-gray-700">
                Your reviews
              </p>
            </div>
          </div>
          <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md m-5">
            <div className="xs:flex xs:flex-col sm:flex">
              <div className="sm:w-1/3 xs:w-100">
                <div className="flex flex-col p-5">
                  <h2>Choose Brand(s)</h2>
                  <hr className="pb-3"/>
                  {brand_list.map((brand, index)=>
                    <div className="mb-1" key={index}>
                      <input type="checkbox" className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" value={brand.id} id={brand.id} onChange={this.handleInputChange} checked={filter.brand_id_list.includes(parseInt(brand.id))}/>
                      <label htmlFor={brand.id} className="text-sm ml-3">{brand.name}</label>
                    </div>
                  )}
                  <h2 className="mt-3">Date Range</h2>
                  <hr className="pb-3"/>
                  <div className="items-center">
                    <div className="flex flex-col">
                      <div>
                        <label className="text-sm text-gray-500 mr-3">Start</label>
                        <DatePicker
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          selected={start_date}
                          onChange={this.setStart}
                          dateFormat="yyyy-MM-dd"
                        />
                        <label className="text-sm text-gray-500 mr-3">End</label>
                        <DatePicker
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          selected={end_date}
                          onChange={this.setEnd}
                          dateFormat="yyyy-MM-dd"
                        />
                      </div>
                    </div>
                  </div>
                  {original_review_list.length > 0 ?
                  <>
                    <hr className="pb-3"/>
                    <div className="items-center">
                      <h6>Score Filter</h6>
                      <div className="mt-1">
                        <div className="inline-block relative w-64">
                          <select
                          name="score"
                          id="score"
                          value={filter_score}
                          onChange={this.filterScore}
                          className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                            <option value={0}>Select a Rating</option>
                            <option value={1}>1 Star</option>
                            <option value={2}>2 Star</option>
                            <option value={3}>3 Star</option>
                            <option value={4}>4 Star</option>
                            <option value={5}>5 Star</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {organization.candidate_list ?
                    <>
                    <hr className="pb-3"/>
                      <h2>Label Filter</h2>

                    {organization.candidate_list.map((candidate, index)=>
                      <div className="mb-1" key={index}>
                        <input type="checkbox" className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" value={candidate} id={candidate} onChange={this.filterLabel} checked={filter_label.includes(candidate)}/>
                        <label htmlFor={candidate} className="text-sm ml-3">{candidate}</label>
                      </div>
                    )}
                    </>
                    : null}
                  </>
                  : null}
                </div>
                <div>
                  <button type="button"
                  className="m-5 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto" onClick={this.fetchResults}>Fetch</button>
                </div>
              </div>
              <div className="sm:w-2/3 xs:w-100 sm:h-[calc(80dvh)]">
                <div className="flex flex-col p-5 overflow-y-auto h-full">
                  <h2 className="">Results</h2>
                  {review_list.map((review, index)=>
                    <ReviewCard review={review} key={index} generate={this.generate}/>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        )
      }
    }
  )
)

export default withParams(OrgReviews2);


// <th scope="col" className="px-6 py-4 font-medium text-gray-900">Locations</th>
