import { makeAutoObservable, computed, action } from "mobx";
import { makePersistable, clearPersistedStore as clearPersist, stopPersisting as stopPersist, startPersisting as startPersist, isHydrated, isPersisting } from "mobx-persist-store";
import { persistStore } from "../utils/mobx.utils";
import { connectionPost } from '../utils/connection';

class UserStore {
  constructor() {
    // makeAutoObservable(this);
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
          name: "UserStore",
          properties: ['ui_version_id', 'id', 'token', 'hash_id', 'first_name', 'last_name', 'full_name', 'email', 'password', 'password_verify', 'sys_status_id', 'is_admin', 'status', 'db_version', 'role_list'],
          storage: window.localStorage
        });
  }

  ui_version_id = 0;
  id = 0;
  token = '';
  hash_id = '';
  first_name = '';
  last_name = '';
  full_name = '';
  email = '';
  password = '';
  password_verify = '';
  sys_status_id = 0;
  is_admin = 0;
  status = '';
  db_version = '';
  role_list = [];


  get is_authenticated() {
  	if (this.token !== ''){
  		return this.token;
  	} else {
  		return false;
  	}
  }

  setUiVersion(version){
    this.ui_version_id = version;
  }

	get_token(email, password){
    return connectionPost('token', {}, {username: email,password: password}
      ).then((res) => {
        console.log(res);
        if (res && res.data.token){
          // should return: user, role list,
          this.db_version = res.data.db_version;
          this.permissions = res.data.user.permissions;
          this.id = res.data.user.id;
          this.token = res.data.token;
          this.hash_id = res.data.user.hash_id;
          this.first_name = res.data.user.first_name;
          this.last_name = res.data.user.last_name;
          this.full_name = res.data.user.full_name;
          this.email = res.data.user.email;
          this.sys_status_id = res.data.user.sys_status_id;
          this.status = res.data.user.status;
          this.is_admin = res.data.user.is_admin
          this.role_list = res.data.user.role_list;
        } else {
          console.log("Incorrect log in credentials");
        }
        return res;
      }).catch((error) => {
        console.log("login error ", error);
        return error;
      });
    }

    resetPassword(email, password, password_new){
      return connectionPost('reset-password', {user:{email:email, password:password, password_new:password_new}}, {auth: {username: email,password: ''}
        }).then((res) => {
          if (res.data.token){
            // should return: user, role list,
            this.db_version = res.data.db_version;
            this.permissions = res.data.user.permissions;
            this.id = res.data.user.id;
            this.token = res.data.token;
            this.hash_id = res.data.user.hash_id;
            this.first_name = res.data.user.first_name;
            this.last_name = res.data.user.last_name;
            this.full_name = res.data.user.full_name;
            this.email = res.data.user.email;
            this.sys_status_id = res.data.user.sys_status_id;
            this.status = res.data.user.status;
            this.is_admin = res.data.user.is_admin;
            this.role_list = res.data.user.role_list;
          }
          return res;
        }).catch((error) => {
          console.log("Reset error ", error);
          return error;
        });
      }


    hasPermission(fx, level){
      if (fx === 'default' || fx === 'error'){
        return true;
      } else if (this.permissions[fx] && this.permissions[fx] >= level){
        return true;
      }
      return false;
    }

    async logout() {
      this.id = 0;
      this.token = '';
      this.hash_id = '';
      this.first_name = '';
      this.last_name = '';
      this.full_name = '';
      this.email = '';
      this.password = '';
      this.password_verify = '';
      this.sys_status_id = 0;
      this.is_admin = 0;
      this.status = '';
      this.db_version = '';
      this.role_list = [];      
      return await clearPersist(this);
    }

    stopPersist() {
      stopPersist(this);
    }

    startPersist() {
      startPersist(this);
    }

    get isHydrated() {
      return isHydrated(this);
    }

    get isPersisting() {
      return isPersisting(this);
    }
}

export default new UserStore();
