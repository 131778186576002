import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionGet, connectionPost } from '../../utils/connection';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import ReviewCard from './reviewcard';
import 'react-toastify/dist/ReactToastify.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const JobResult = inject("UserStore")(
  observer(
    class JobResult1 extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          brand:null,
          query:'',
          summary:'',
          count:null,
          db:null,
          docs:[],
          sentiment:0,
          loadingSummary:false,
          loadingQuestion:false,
          vector_db_id: "",
          job_result:null
        }
    	}

      componentDidMount(){
        this.load();
      }

      handleInputChange = (e) => {
        // const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const {target:{name, value}} = e;
        this.setState({[name]: value});
        // this.setState({e.target.name:value});
      }

      load = () => {
        connectionGet('job-result/' + this.props.params.job_result_hash_id, {}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              job_result:res.data.job_result,
              brand:res.data.brand,
              sentiment:res.data.sentiment,
              vector_db_id:res.data.vector_db_id
            });
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      summarize = () => {
        this.setState({
          loadingSummary:true
        },()=>{
          this.updateFilter();
          connectionPost('summarize', {brand_id:this.state.brand.id, input_text:this.state.query, sentiment:this.state.sentiment, vector_db_id:this.state.vector_db_id}, {username: this.props.UserStore.token})
          .then((res)=>{
            console.log(res);
            if (res.data.errors === 0){
              this.setState({
                summary:res.data.result,
                docs:res.data.docs,
                loadingSummary:false
              })
            }
            this.setState({
              loadingSummary:false
            })
          })
          .catch((error)=>{
            console.log(error);
            toast.error(error.response.statusText, toastParams);
            this.setState({
              loadingSummary:false
            })
          });
        })
      }

      question = () => {
        this.setState({
          loadingQuestion:true
        },()=>{
          connectionPost('question', {brand_id:this.state.brand.id, input_text:this.state.query, sentiment:this.state.sentiment, vector_db_id:this.state.vector_db_id}, {username: this.props.UserStore.token})
          .then((res)=>{
            console.log(res);
            if (res.data.errors === 0){
              this.setState({
                summary:res.data.result,
                docs:res.data.docs,
                loadingQuestion:false
              })
            }
            this.setState({
              loadingQuestion:false
            })
          })
          .catch((error)=>{
            console.log(error);
            toast.error(error.response.statusText, toastParams);
            this.setState({
              loadingSummary:false
            })
          });
        })
      }

      render(){
        const {open, brand, query, summary, docs, loadingSummary, loadingQuestion, db, vdb_list, vector_db_id, job_result} = this.state;
        if (brand === null) return <div>Loading</div>;
        return (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="sm:flex sm:items-center m-5">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">{brand.name}</h1>
              <p className="mt-2 text-sm text-gray-700">
                {job_result.name} - Job Results
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            </div>
          </div>
          <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md m-5">
            <form className="space-y-8 divide-y divide-gray-200">
              <div className="space-y-8 divide-y divide-gray-200">
                <div className="lg:pt-8">
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-12 px-5 lg:px-20">
                      <div className="mt-1">
                        <textarea className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500" rows="3" placeholder="ask me a question or give me a topic to summarize..." name="query" id="query" value={query} onChange={this.handleInputChange}></textarea>
                      </div>
                      <div className="xs:flex xs:flex-col space-y-22 sm:flex sm:flex-row mt-5">
                        <div className="inline-flex shadow-sm">
                          <div className="flex flex-col">
                            <div>
                              <button type="button" className="py-1 px-2 inline-flex justify-center items-center gap-2 first:rounded-l-md first:ml-0 last:rounded-r-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all text-sm dark:focus:ring-offset-gray-800" onClick={this.summarize}>
                                <span className="sm:text-xs md:text-sm">Summarize</span>
                                {loadingSummary ?
                                  <span className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading"></span>
                                :
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                                  </svg>
                                }
                              </button>
                              <button type="button" className="xs:min-w-full py-1 px-2 inline-flex justify-center items-center gap-2 sm:first:rounded-l-lg first:ml-0 last:rounded-r-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800 text-sm" onClick={this.question}>
                                <span className="sm:text-xs md:text-sm">Answer</span>
                                {loadingQuestion ?
                                  <span className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading"></span>
                                :
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                  </svg>
                                }
                              </button>
                            </div>
                            <div>
                              <label htmlFor="vector_db" className="block text-sm font-medium text-gray-700">
                                Vector DB Used: {job_result.start_date}-{job_result.end_date}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {summary !== '' ?
                        <>
                          <div className="mt-10">
                            <h2 className="text-xl font-semibold text-gray-900">Results</h2>
                            {summary !== '' ? <p className="italic text-gray-500">{summary}</p> : <p className="italic">...</p>}
                          </div>
                          <div className="mt-10 mb-20">
                            <h2 className="text-xl font-semibold text-gray-900">Sources</h2>
                            {docs.length > 0 ? docs.map((doc, i)=> {
                              return (<ReviewCard review={doc} key={i} generate={this.generate}/>);
                            }) : ''}
                          </div>                        
                        </>
                        :
                        job_result.result_json.result_list ? job_result.result_json.result_list.map((result, index) => {
                          return (
                            <>
                            <div className="mt-10">
                              <h2 className="text-xl font-semibold text-gray-900">{result.prompt}</h2>
                              {result.result !== '' ? <p className="italic text-gray-500">{result.result}</p> : <p className="italic">...</p>}
                            </div>
                            <div className="mt-10 mb-20">
                              <h2 className="text-xl font-semibold text-gray-900">Sources</h2>
                              {result.docs.length > 0 ? result.docs.map((doc, i)=> {
                                return (<ReviewCard review={doc} key={i} generate={this.generate}/>);
                              }) : ''}
                            </div>
                            </>
                          );
                        }):
                          <div className="">
                            <h1>{job_result.result_json.message}</h1>
                          </div>
                        }
                      }
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
        )
      }
    }
  )
)

export default withParams(JobResult);


// <th scope="col" className="px-6 py-4 font-medium text-gray-900">Locations</th>
// <DatePicker
//   className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
//   selected={start_date}
//   onChange={this.setStart}
//   dateFormat="yyyy-MM-dd"
// />
// , [0,'Custom Range']
// <select className="py-3 px-4 pr-9 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500" onChange={this.setDateRange}>
// {date_list.map((range, index) =>
//   <option key={index} value={range[0]}>{range[1]}</option>
// )}
// </select>
