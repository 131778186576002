import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionPost, connectionPut } from '../../utils/connection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const OrgQuestionnaireForm = inject("UserStore")(
  observer(
    class OrgQuestionnaireForm1 extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          organization:this.props.organization,
          qr_questionnaire:{id:null, questionnaire_json:'', organization_id:null}
        }
    	}

      componentDidMount(){
        console.log(this.props.qr_questionnaire);
        if (this.props.qr_questionnaire !== null){
          this.setState({
            qr_questionnaire:this.props.qr_questionnaire,
            organization:this.props.organization
          })
        }
      }

      componentWillReceiveProps(nextProps){
        if (nextProps.qr_questionnaire !== null){
          this.setState({
            qr_questionnaire:nextProps.qr_questionnaire,
            organization:nextProps.organization
          })
        }
      }

      handleInputChange = (e) => {
        let updated_obj = Object.assign({}, this.state.qr_questionnaire);
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        updated_obj[e.target.name] = value;
        this.setState({qr_questionnaire:updated_obj});
      }

      handleSubmit = (e) => {
        e.preventDefault();

        let qr_questionnaire = this.state.qr_questionnaire;

        let organization = this.state.organization;

        if (qr_questionnaire.id){
          connectionPut('qr-questionnaire/' + organization.hash_id + '/' + qr_questionnaire.hash_id, {qr_questionnaire:qr_questionnaire}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              // alert(res.data.message)
              this.props.messageHandler('success', res.data.message);
              this.props.handler();
              // toast.success(res.data.message, toastParams);
            } else {
              this.props.messageHandler('error', res.data.message);
              // toast.error(res.data.message, toastParams);
            }
          })
          // .catch((error)=>{toast.error(error.response.statusText, toastParams);});
        } else {
          connectionPost('qr-questionnaire/' + organization.hash_id, {qr_questionnaire:qr_questionnaire}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              this.setState({qr_questionnaire:res.data.qr_questionnaire},()=>{
                this.props.messageHandler('success', res.data.message);
                this.props.handler();
              });
            } else {
              this.props.messageHandler('error', res.data.message);
            }
          })
          // .catch((error)=>{toast.error(error.response.statusText, toastParams);});
        }
      }

      render(){
        const {qr_questionnaire, organization} = this.state;
        console.log(qr_questionnaire);
        if (qr_questionnaire === null){
           return (<span>Loading</span>);
        } else {
          return (
            <form className="space-y-8 divide-y divide-gray-200" onSubmit={this.handleSubmit}>
              <div className="space-y-8 divide-y divide-gray-200">
                <div className="pt-8">
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                      <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                        Questionnaire JSON
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="questionnaire_json"
                          name="questionnaire_json"
                          value={qr_questionnaire.questionnaire_json}
                          rows={3}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <p className="mt-2 text-sm text-gray-500">JSON representation of the questionnaire.</p>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-end">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }
      }
    }
  )
)

export default OrgQuestionnaireForm;
