import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionGet } from '../../utils/connection';
import { useParams } from "react-router-dom";
import Slide from './slide';
import BrandProductForm from '../forms/brand.product.form';
import BrandProductRow from './brand/brand.product.row';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const BrandProducts = inject("UserStore")(
  observer(
    class BrandProductList extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          open:false,
          brand:null,
          product:null,
          productList:[]
        }
    	}

      componentDidMount(){
        this.load();
      }

      load = () => {
        connectionGet('product/'  + this.props.params.brand_hash_id, {}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              productList:res.data.product_list,
              brand:res.data.brand
            })
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      toggle = () => {
        this.setState({
          open:true
        })
      }

      close = () => {
        this.setState({
          open:false
        }, ()=>{this.load()})
      }

      message = (status, message) => {
        if (status === 'success'){
          toast.success(message, toastParams);
        } else {
          toast.error(message, toastParams);
        }
      }

      openEdit = (product) => {
        console.log(product)
        this.setState({
          open:true,
          product:product
        })
      }

      render(){
        const {open, productList, product, brand} = this.state;
        if (brand === null) {
          return (
            <span>Loading</span>
          )
        } else {
        return (
        <>
          <Slide open={open} component={<BrandProductForm handler={this.close} messageHandler={this.message} brand={brand} product={product}/>} panelTitle={'Brand Product Information'}/>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="sm:flex sm:items-center m-5">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">{brand.name} Products</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all {brand.name}'s  Products.
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                onClick={this.toggle}
              >
                Add Product for {brand.name}
              </button>
            </div>
          </div>
          <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md m-5">
            <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Name</th>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Description</th>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900 w-1/5">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100 border-t border-gray-100">
              {productList.length > 0 ?
                productList.map((product, index)=>
                  <BrandProductRow product={product} key={index} openHandler={this.openEdit}/>
                )
              : null}
              </tbody>
            </table>
          </div>
        </>
      );
        }
      }
    }
  )
)

export default withParams(BrandProducts);
