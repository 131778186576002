import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionGet, connectionPost } from '../../utils/connection';
import { useParams } from "react-router-dom";
import Slide from './slide';
import BrandLocationForm from '../forms/brand.location.form';
import BrandLocationRow from './brand/brand.location.row';
import { ToastContainer, toast } from 'react-toastify';
import AutoCompleteComponent from '../forms/components/AutoComplete'
import { geocodeByPlaceId } from 'react-places-autocomplete';
import 'react-toastify/dist/ReactToastify.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const BrandLocations = inject("UserStore")(
  observer(
    class BrandLocationList extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          open:false,
          brand:null,
          location:null,
          locationList:[],
          selectedValue:null
        }
    	}

      componentDidMount(){
        console.log(this.props.params);
        this.load();
      }

      load = () => {
        connectionGet('location/'  + this.props.params.brand_hash_id, {}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              locationList:res.data.location_list,
              brand:res.data.brand
            })
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      save = () => {
        console.log('hello')
        connectionPost('location/'  + this.props.params.brand_hash_id, {g_result:this.state.selectedValue}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              locationList:res.data.location_list,
              brand:res.data.brand
            })
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      toggle = () => {
        this.setState({
          open:true
        })
      }

      close = () => {
        this.setState({
          open:false
        }, ()=>{this.load()})
      }

      message = (status, message) => {
        if (status === 'success'){
          toast.success(message, toastParams);
        } else {
          toast.error(message, toastParams);
        }
      }

      openEdit = (location) => {
        console.log(location)
        this.setState({
          open:true,
          location:location
        })
      }

      handleSelect = (value) => {
        // console.log(value);
        if (value){
          geocodeByPlaceId(value.value.place_id)
            .then(results => {
              value["results"] = results;
              this.setState({
                selectedValue:value
              },()=>{console.log(this.state.selectedValue)});
            })
            .catch(error => console.error(error));
        }
      }

      render(){
        const {open, locationList, location, brand, selectedValue} = this.state;
        if (brand === null) {
          return (
            <span>Loading</span>
          )
        } else {
        return (
        <>
          <Slide open={open} component={<BrandLocationForm handler={this.close} messageHandler={this.message} brand={brand} location={location}/>} panelTitle={'Brand Location Information'}/>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="sm:flex sm:items-center m-5">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">{brand.name} Locations</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all {brand.name}'s  Locations.
              </p>
            </div>
            <div className="sm:flex-1">
              <form className="space-y-8 divide-y divide-gray-200 pt-8 mt-6">
                <div className="flex flex-row">
                  <div className="flex-1">
                    <AutoCompleteComponent handleSelect={this.handleSelect}/>
                  </div>
                  <button
                    type="button"
                    onClick={this.save}
                    className={selectedValue ? "ml-3 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" : "opacity-50 cursor-not-allowed ml-3 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"}
                  >
                    Add
                  </button>
                </div>
                {selectedValue ? <div className="pt-10">{selectedValue.label}</div> : null}
              </form>
            </div>
          </div>
          <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md m-5">
            <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Name</th>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Description</th>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900 w-1/5">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100 border-t border-gray-100">
              {locationList.length > 0 ?
                locationList.map((location, index)=>
                  <BrandLocationRow location={location} key={index} openHandler={this.openEdit}/>
                )
              : null}
              </tbody>
            </table>
          </div>
        </>
      );
        }
      }
    }
  )
)

export default withParams(BrandLocations);
