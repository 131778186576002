import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react'
import { useParams } from "react-router-dom";
import { connectionPost, connectionPut, connectionGet } from '../../utils/connection';
import { JSONEditor } from 'react-json-editor-viewer';
import ReactJsonEditor from 'jsoneditor-for-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const OrgQuestionnaire = inject("UserStore")(
  observer(
    class OrgQuestionnaire1 extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          questionnaire:{question_list:[]},
          organization:null,
          open:false
        }
    	}

      componentDidMount(){
        this.load();
      }

      load = () => {
        let connStr = 'qr-questionnaire/' +this.props.params.organization_hash_id;
        if (this.props.params.qr_hash_id){
          connStr += '/' + this.props.params.qr_hash_id
        }
        connectionGet(connStr, {}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              organization:res.data.organization,
              questionnaire:res.data.qr_questionnaire,
              sys_question_type_list:res.data.sys_question_type_list
            })
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }


      message = (status, message) => {
        if (status === 'success'){
          toast.success(message, toastParams);
        } else {
          toast.error(message, toastParams);
        }
      }

      onJsonChange = (key, value, parent, data) => {
        console.log(key, value, parent, data);
      }

      editorChangeHandler = (values) => {
        console.log('new values', values)
        this.setState({values: values})
      }

      render(){
        const {questionnaire, organization, open, question, sys_question_type_list} = this.state;
        if (!questionnaire || !organization) return "OrgQuestionnaire not set";
        return (
          <>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              pauseOnVisibilityChange={false}
              draggable={false}
              pauseOnHover={false}
            />
            <div className="sm:flex sm:items-center m-5">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">{organization.name} Questionnaire</h1>
                <p className="mt-2 text-sm text-gray-700">
                  Questionnaire Editing
                </p>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              </div>
            </div>
            <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md m-5 p-5">
            {questionnaire.questionnaire_json ?
            <JSONEditor
              data={questionnaire.questionnaire_json}
              collapsible
              onChange={this.onJsonChange}
              view="dual"
            />
            : null}
            </div>
            <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md m-5 p-5">
            {questionnaire.questionnaire_json ?
              <ReactJsonEditor values={questionnaire.questionnaire_json} onChange={(values) => {this.editorChangeHandler(values)}}/>
            : null}
            </div>
          </>
        );
      }
    }
  )
)

export default withParams(OrgQuestionnaire);
