import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionGet, connectionPost } from '../../utils/connection';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import OrgQuestionnaireRow from './brand/org.questionnaire.row';
import OrgQuestionnaireForm from '../forms/org.questionnaire.form';
import Slide from './slide';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const OrgQuestionnaires = inject("UserStore")(
  observer(
    class OrgQuestionnaires1 extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          organization:null,
          qr_questionnaire_list:[],
          open:false
        }
    	}

      componentDidMount(){
        this.load();
      }

      load = () => {
        console.log(this.props.params.organization_hash_id);
        connectionGet('qr-questionnaire/' +this.props.params.organization_hash_id, {}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              organization:res.data.organization,
              qr_questionnaire_list:res.data.qr_questionnaire_list
            })
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      message = (status, message) => {
        if (status === 'success'){
          toast.success(message, toastParams);
        } else {
          toast.error(message, toastParams);
        }
      }

      open = () => {
        this.setState({
          open:true
        })
      }

      close = () => {
        this.setState({
          open:false
        },()=>{this.load()})
      }

      render(){
        const {organization, qr_questionnaire_list, open} = this.state;
        if (organization === null) return <div>Loading</div>;
        return (
        <>
          <Slide open={open} component={<OrgQuestionnaireForm qr_questionnaire={null} handler={this.close} messageHandler={this.message} organization={organization}/>} panelTitle={'Questionnaire Information'}/>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="sm:flex sm:items-center m-5">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">{organization.name}</h1>
              <p className="mt-2 text-sm text-gray-700">
                List of Questionnaires
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                onClick={this.open}
              >
                Add Questionnaire
              </button>
            </div>
          </div>
          <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md m-5">
            <form className="space-y-8 divide-y divide-gray-200">
              <div className="space-y-8 divide-y divide-gray-200">
                <div className="lg:pt-8">
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-12 px-5 lg:px-20 mb-10">
                      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                          <div className="overflow-hidden">
                            <table className="w-full border-collapse bg-white text-left text-sm text-gray-500 table-auto">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th scope="col" className="px-6 py-4 font-medium text-gray-900 w-2/3">JSON</th>
                                  <th scope="col" className="px-6 py-4 font-medium text-gray-900 w-1/3 hidden md:table-cell">Actions</th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                                {qr_questionnaire_list ? qr_questionnaire_list.map((qr_questionnaire, i)=>
                                  {
                                    const stringObj = JSON.stringify(qr_questionnaire.questionnaire_json);
                                    return (
                                  <tr key={i}>
                                    <td className="xs:whitespace-nowrap px-6 py-4 w-1/3 hidden md:table-cell">
                                      <div className="text-sm">
                                        <div className="font-medium text-gray-700">{stringObj}</div>
                                      </div>
                                    </td>
                                    <td className="xs:whitespace-nowrap px-6 py-4 w-2/3 hidden md:table-cell">
                                      <a x-data="{ tooltip: 'Delete' }" href={"/qr-naire/" + organization.hash_id + "/" + qr_questionnaire.hash_id}>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          stroke="currentColor"
                                          className="h-6 w-6"
                                          x-tooltip="tooltip"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                          />
                                        </svg>
                                      </a>
                                    </td>
                                  </tr>
                                  )}
                                ) :
                                <tr>
                                  <td colSpan="3">No Questionnaires</td>
                                </tr>
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
        )
      }
    }
  )
)

export default withParams(OrgQuestionnaires);


// <th scope="col" className="px-6 py-4 font-medium text-gray-900">Locations</th>
// <DatePicker
//   className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
//   selected={start_date}
//   onChange={this.setStart}
//   dateFormat="yyyy-MM-dd"
// />
// , [0,'Custom Range']
// <select className="py-3 px-4 pr-9 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500" onChange={this.setDateRange}>
// {date_list.map((range, index) =>
//   <option key={index} value={range[0]}>{range[1]}</option>
// )}
// </select>
