import React, { Component, Fragment } from 'react';

class BrandReviewSourceRow extends Component {
  constructor(props){
      super(props);
  }

  render() {
    const {source, openHandler, checkJob, scrapeJob, createJob} = this.props;
    return (
      <React.Fragment>
      <tr className="hover:bg-gray-50">
        <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
          <div className="text-sm">
            <div className="font-medium text-gray-700">{source.sys_review_source} <small>({source.id} <br/>on {source.last_queue ? source.last_queue.create_timestamp : null})</small></div>
          </div>
        </th>
        <td className="px-6 py-4">
          <div className="text-sm">
            <div className="font-medium text-gray-700">{source.url}</div>
          </div>
        </td>
        <td className="px-6 py-4">
          <div className="text-sm">
            <div className="font-medium text-gray-700">
            {!source.last_queue ?
              <button type="button" className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-xs dark:focus:ring-offset-gray-800" onClick={()=>createJob(source.id)}>Create</button> :
            source.last_queue.is_complete === 1 ?
              <button type="button" className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-xs dark:focus:ring-offset-gray-800" onClick={()=>createJob(source.id)}>Create</button> :
            source.last_queue.result_count > source.last_queue.processed_count ?
              <button type="button" className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-xs dark:focus:ring-offset-gray-800" onClick={()=>scrapeJob(source)}>Scrape</button>
            :
              <div className="inline-flex rounded-md shadow-sm">
                <button type="button" className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-amber-500 text-white hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 transition-all text-xs dark:focus:ring-offset-gray-800" onClick={()=>checkJob(source)}>Check</button>
                <button type="button" className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-xs dark:focus:ring-offset-gray-800" onClick={()=>createJob(source.id)}>Create</button>
              </div>
            }
            </div>
          </div>
        </td>
        <td className="px-6 py-4">
          <div className="flex justify-end gap-4">
            <a x-data="{ tooltip: 'Delete' }" href="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
                x-tooltip="tooltip"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </a>
            <a x-data="{ tooltip: 'Edit' }" href="#" onClick={()=>{openHandler(source)}}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
                x-tooltip="tooltip"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                />
              </svg>
            </a>
          </div>
        </td>
      </tr>

      </React.Fragment>
      );
    }
}
export default BrandReviewSourceRow;


// <td className="px-6 py-4">
//   <div className="flex gap-2">
//     <span
//       className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600"
//     >
//       Design
//     </span>
//     <span
//       className="inline-flex items-center gap-1 rounded-full bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600"
//     >
//       Product
//     </span>
//     <span
//       className="inline-flex items-center gap-1 rounded-full bg-violet-50 px-2 py-1 text-xs font-semibold text-violet-600"
//     >
//       Develop
//     </span>
//   </div>
// </td>
