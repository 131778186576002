import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionGet, connectionPost } from '../../utils/connection';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import ReviewCard from './reviewcard';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};


class Filter{
  date_range = [0, 'Custom Range'];
  start_date = new Date().setMonth(new Date().getMonth()-1);
  end_date = new Date();
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const ChatGPT = inject("UserStore")(
  observer(
    class ChatGPT1 extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          vdb_list:[],
          brand:null,
          query:'',
          summary:'',
          count:null,
          db:null,
          docs:[],
          loadingSummary:false,
          loadingQuestion:false,
          sentiment:0,
          filter:new Filter(),
          date_list:[[7,'Last Week'],[30,'Last 30 Days'],[90,'Last 90 Days'],[365,'Last Year']],
          start_date: new Date().setMonth(new Date().getMonth()-1),
          end_date: new Date(),
          vector_db_id: ""
        }
    	}

      componentDidMount(){
        console.log(this.props);
        this.load();
      }

      handleInputChange = (e) => {
        // const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const {target:{name, value}} = e;
        this.setState({[name]: value});
        // this.setState({e.target.name:value});
      }

      handleToggle = (e) => {
        let value = 1;
        if (!e.target.checked){
          value = 0
        }
        this.setState({sentiment:value},()=>{console.log(this.state.sentiment)});
      }

      setStart = (date) => {
        let updated_filter = Object.assign({}, this.state.filter);
        updated_filter.end_date = moment(this.state.end_date).format('MM/DD/YYYY');
        updated_filter.start_date = moment(date).format('MM/DD/YYYY');
        updated_filter.date_range = [0, 'Custom Range'];
        this.setState({
          selected_date: updated_filter.date_range,
          start_date: date,
          filter:updated_filter
        },()=>{
          this.getReviewCount()
        });
      }

      setEnd = (date) => {
        let updated_filter = Object.assign({}, this.state.filter);
        updated_filter.start_date = moment(this.state.start_date).format('MM/DD/YYYY');
        updated_filter.end_date = moment(date).format('MM/DD/YYYY');
        updated_filter.date_range = [0, 'Custom Range'];
        this.setState({
          selected_date: updated_filter.date_range,
          end_date: date,
          filter:updated_filter
        },()=>{
          console.log('world')
          this.getReviewCount()
        });
      }

      updateFilter = () => {
        let updated_filter = Object.assign({}, this.state.filter);
        updated_filter.start_date = moment(this.state.start_date).format('MM/DD/YYYY');
        updated_filter.end_date = moment(this.state.end_date).format('MM/DD/YYYY');
        this.setState({
          filter:updated_filter
        });
      }

      setDateRange = (e) => {
        let range = this.state.date_list.find(x => x[0] == parseInt(e.target.value));
        let updated_filter = Object.assign({}, this.state.filter);
        updated_filter.date_range = range;
        if (range[0] === 0){
          // set the start and end
          updated_filter.end_date = moment(this.state.end_date).format('MM/DD/YYYY');
          updated_filter.start_date = moment(this.state.start_date).format('MM/DD/YYYY');
        }
        this.setState({
          selected_date:range,
          filter:updated_filter
        },()=>{
          this.getReviewCount()
        })
      }

      getReviewCount = () => {
        connectionPost('review-count', {entity_type:"BRAND", sentiment:this.state.sentiment, entity_id:this.state.brand.id, filter:this.state.filter}, {username: this.props.UserStore.token})
        .then((res)=>{
          console.log(res);
          if (res.data.errors === 0){
            this.setState({
              count:res.data.count,
              db:res.data.db
            })
          }
        });
      }

      getVectorDbList = () => {
        connectionPost('vdb-lookup', {entity_type:"BRAND", entity_id:this.state.brand.id}, {username: this.props.UserStore.token})
        .then((res)=>{
          console.log(res);
          if (res.data.errors === 0){
            this.setState({
              vdb_list:res.data.vdb_list
            })
          }
        });
      }

      load = () => {
        connectionGet('brand/' +this.props.params.organization_hash_id + '/' + this.props.params.brand_hash_id, {}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              brand:res.data.brand
            },()=>{this.getVectorDbList()})
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      summarize = () => {
        this.setState({
          loadingSummary:true
        },()=>{
          this.updateFilter();
          connectionPost('summarize', {brand_id:this.state.brand.id, input_text:this.state.query, sentiment:this.state.sentiment, filter:this.state.filter, vector_db_id:this.state.vector_db_id}, {username: this.props.UserStore.token})
          .then((res)=>{
            console.log(res);
            if (res.data.errors === 0){
              this.setState({
                summary:res.data.result,
                docs:res.data.docs,
                loadingSummary:false
              })
            }
            this.setState({
              loadingSummary:false
            })
          })
          .catch((error)=>{
            console.log(error);
            toast.error(error.response.statusText, toastParams);
            this.setState({
              loadingSummary:false
            })
          });
        })
      }

      question = () => {
        this.setState({
          loadingQuestion:true
        },()=>{
          let updated_filter = Object.assign({}, this.state.filter);
          updated_filter.start_date = moment(this.state.start_date).format('MM/DD/YYYY');
          updated_filter.end_date = moment(this.state.end_date).format('MM/DD/YYYY');
          this.setState({
            filter:updated_filter
          },()=>{
            connectionPost('question', {brand_id:this.state.brand.id, input_text:this.state.query, sentiment:this.state.sentiment, filter:this.state.filter, vector_db_id:this.state.vector_db_id}, {username: this.props.UserStore.token})
            .then((res)=>{
              console.log(res);
              if (res.data.errors === 0){
                this.setState({
                  summary:res.data.result,
                  docs:res.data.docs,
                  loadingQuestion:false
                })
              }
              this.setState({
                loadingQuestion:false
              })
            })
            .catch((error)=>{
              console.log(error);
              toast.error(error.response.statusText, toastParams);
              this.setState({
                loadingSummary:false
              })
            });
          });
        })
      }

      render(){
        const {open, brand, query, summary, docs, loadingSummary, loadingQuestion, sentiment, start_date, end_date, date_list, count, db, vdb_list, vector_db_id} = this.state;
        if (brand === null) return <div>Loading</div>;
        return (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="sm:flex sm:items-center m-5">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">{brand.name}</h1>
              <p className="mt-2 text-sm text-gray-700">
                Ask me about reviews
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            </div>
          </div>
          <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md m-5">
            <form className="space-y-8 divide-y divide-gray-200">
              <div className="space-y-8 divide-y divide-gray-200">
                <div className="lg:pt-8">
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-12 px-5 lg:px-20">
                      <div className="mt-1">
                        <textarea className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500" rows="3" placeholder="ask me a question or give me a topic to summarize..." name="query" id="query" value={query} onChange={this.handleInputChange}></textarea>
                      </div>
                      <div className="xs:flex xs:flex-col space-y-22 sm:flex sm:flex-row mt-5">
                        <div className="inline-flex shadow-sm">
                          <div className="flex flex-col">
                            <div>
                              <button type="button" className="py-1 px-2 inline-flex justify-center items-center gap-2 first:rounded-l-md first:ml-0 last:rounded-r-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all text-sm dark:focus:ring-offset-gray-800" onClick={this.summarize}>
                                <span className="sm:text-xs md:text-sm">Summarize</span>
                                {loadingSummary ?
                                  <span className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading"></span>
                                :
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                                  </svg>
                                }
                              </button>
                              <button type="button" className="xs:min-w-full py-1 px-2 inline-flex justify-center items-center gap-2 sm:first:rounded-l-lg first:ml-0 last:rounded-r-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800 text-sm" onClick={this.question}>
                                <span className="sm:text-xs md:text-sm">Answer</span>
                                {loadingQuestion ?
                                  <span className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading"></span>
                                :
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                  </svg>
                                }
                              </button>
                            </div>
                            <div>
                              <label htmlFor="vector_db" className="block text-sm font-medium text-gray-700">
                                Select VDB
                              </label>
                              <div className="mt-1">
                                <div className="inline-block relative w-64">
                                  <select
                                  name="vector_db_id"
                                  id="vector_db_id"
                                  value={vector_db_id}
                                  onChange={this.handleInputChange}
                                  className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                    <option value={""}>Select</option>
                                  {vdb_list ? vdb_list.map((vdb, i) => {
                                    return (<option value={vdb.id} key={i}>{vdb.sentiment === 0 ? '- ' : '+ '}{vdb.start_date} to {vdb.end_date}</option>);
                                  }) : <option>Loading...</option>}
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                        {vector_db_id !== "" ? null :
                        <>
                        <div className="sm:ml-2 xs:ml-0 xs:mt-2 items-center">
                          <label className="text-sm text-gray-500 mr-3 ">Negative</label>
                          <input type="checkbox" id="hs-basic-with-description" className="relative shrink-0 w-[3.25rem] h-7 bg-gray-100 checked:bg-none checked:bg-blue-600 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 border border-transparent ring-1 ring-transparent focus:border-blue-600 focus:ring-blue-600 ring-offset-white focus:outline-none appearance-none  before:inline-block before:w-6 before:h-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200" value={sentiment} onChange={this.handleToggle}/>
                          <label className="text-sm text-gray-500 ml-3 ">Positive</label>
                        </div>
                        <div className="sm:ml-2 xs:ml-0 items-center">
                          <div className="flex flex-col">
                            <div>
                              <label className="text-sm text-gray-500 mr-3">Start</label>
                              <DatePicker
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                selected={start_date}
                                onChange={this.setStart}
                                dateFormat="yyyy-MM-dd"
                              />
                              <label className="text-sm text-gray-500 mr-3">End</label>
                              <DatePicker
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                selected={end_date}
                                onChange={this.setEnd}
                                dateFormat="yyyy-MM-dd"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="sm:ml-10 sm:flex xs:ml-0 xs:flex xs:flex-col items-center">
                          {count ? <span className="text-xs text-blue-700 md:ml-3 sm:mt-1">{count} Reviews Found</span> : null}
                          {db ? <span className="text-xs text-blue-700 md:ml-3 sm:mt-1">Vector DB Found</span> : null}
                        </div>
                        </>
                        }
                      </div>
                      <div className="mt-10">
                        <h2 className="text-xl font-semibold text-gray-900">Results</h2>
                        {summary !== '' ? <p className="italic text-gray-500">{summary}</p> : <p className="italic">...</p>}
                      </div>
                      <div className="mt-10 mb-20">
                        <h2 className="text-xl font-semibold text-gray-900">Sources</h2>
                        {docs.length > 0 ? docs.map((doc, i)=> {
                          return (<ReviewCard review={doc} key={i} generate={this.generate}/>);
                        }) : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
        )
      }
    }
  )
)

export default withParams(ChatGPT);


// <th scope="col" className="px-6 py-4 font-medium text-gray-900">Locations</th>
// <DatePicker
//   className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
//   selected={start_date}
//   onChange={this.setStart}
//   dateFormat="yyyy-MM-dd"
// />
// , [0,'Custom Range']
// <select className="py-3 px-4 pr-9 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500" onChange={this.setDateRange}>
// {date_list.map((range, index) =>
//   <option key={index} value={range[0]}>{range[1]}</option>
// )}
// </select>
