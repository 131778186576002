import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionGet } from '../../utils/connection';
import Slide from './slide';
import BrandForm from '../forms/brand.form';
import BrandRow from './brand/brand.row';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

/* This example requires Tailwind CSS v2.0+ */
const people = [
  { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
  // More people...
]
const Brands = inject("UserStore")(
  observer(
    class BrandList extends Component{
      constructor(props) {
    	  super(props);
        this.state = {
          open:false,
          brand:null,
          brandList:[]
        }
    	}

      componentDidMount(){
        this.load();
      }

      load = () => {
        connectionGet('brand', {}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              brandList:res.data.brand_list
            })
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      map = (brand) => {
        connectionGet('map/' + brand.hash_id, {}, {username: this.props.UserStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            console.log(res.data.map_json)
          }
        })
        .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
      }

      toggle = () => {
        this.setState({
          open:true
        })
      }

      close = () => {
        this.setState({
          open:false
        },()=>{this.load()})
      }

      message = (status, message) => {
        if (status === 'success'){
          toast.success(message, toastParams);
        } else {
          toast.error(message, toastParams);
        }
      }

      openEdit = (brand) => {
        this.setState({
          open:true,
          brand:brand
        })
      }

      render(){
        const {open, brandList, brand} = this.state;
        return (
        <>
          <Slide open={open} component={<BrandForm handler={this.close} messageHandler={this.message} brand={brand}/>} panelTitle={'Brand Information'}/>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="sm:flex sm:items-center m-5">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Brands</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all Brand.
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                onClick={this.toggle}
              >
                Add Brand
              </button>
            </div>
          </div>
          <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md m-5">
            <table className="w-full border-collapse bg-white text-left text-sm text-gray-500 table-auto">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Name</th>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Description</th>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Sources</th>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Interact</th>
                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100 border-t border-gray-100">
              {brandList.length > 0 ?
                brandList.map((brand, index)=>
                  <BrandRow brand={brand} key={index} openHandler={this.openEdit} mapHandler={this.map}/>
                )
              : null}
              </tbody>
            </table>
          </div>
        </>
        )
      }
    }
  )
)

export default Brands;


// <th scope="col" className="px-6 py-4 font-medium text-gray-900">Locations</th>
