import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react'
import { connectionGet, connectionPost } from '../../utils/connection';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import FormattedReviewText from './FormattedReviewText';
import Stars from './star';
import 'react-toastify/dist/ReactToastify.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const ReviewCard = inject("UserStore")(
  observer(
    class ReviewCard1 extends Component{
      constructor(props){
        super(props)
        this.state = {
          response:'',
          loading:false
        }
      }

      componentDidMount(){
        this.setState({
          response:''
        })
      }

      componentWillReceiveProps(nextProps){
        this.setState({
          response:''
        })
      }

      generate = () => {
        this.setState({
          loading:true
        }, ()=>{
          connectionPost('generate', {review:this.props.review}, {username: this.props.UserStore.token})
          .then((res)=>{
            if (res.data.errors === 0){
              this.setState({
                response:res.data.results,
                loading:false
              })
            }
            this.setState({
              loadingSummary:false
            })
          })
          .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
        })
      }



      render(){
        const {review} = this.props;
        const {response, loading} = this.state;
        let response_text = null
        if (response && response !== ''){
          response_text = response;
        } else if (review.response_text && review.response_text !== '') {
          response_text = review.response_text;
        }
        return (
          <div className="block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] mt-2">
            <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800">
              {review.name ? <>{review.name} on</> : 'FDB Review on '} {review.review_date} at {review.brand}
            </h5>
            {review.sys_review_source && review.sys_review_source.id !== 1 ?
            <div className="flex flex-row p-2">
              <Stars rating_value={review.rating_value}/>
            </div>
            : null }
            {review.sentiment_json ?
              <FormattedReviewText sentiment_json={review.sentiment_json}/>
            :
            <p className="mb-4 text-base text-neutral-600">
              {review.review_text && review.review_text !== '' ? review.review_text : 'No review text was left'}
            </p>
            }
            {response_text && response_text !== '' ? <div className="p-3 rounded border mb-3"><p className="mt-1 mb-4 text-base text-sky-600">{response_text}</p></div> : null}
            {review.classification_list ?
              review.sys_review_source.id !== 1 ?
                <div className="xs:flex xs:flex-col space-y-2 sm:flex sm:flex-row mt-5">
                  <div className="inline-flex shadow-sm">
                    {review.classification_list.map((classification, index)=>
                      {
                        switch (classification.rating_value) {
                          case 1:
                            return (<span key={index} className="inline-flex items-center gap-1.5 py-1.5 px-3 mr-2 rounded-full text-xs font-medium bg-red-500 text-white">{classification.classification}</span>);
                            break;
                          case 2:
                            return (<span key={index} className="inline-flex items-center gap-1.5 py-1.5 px-3 mr-2 rounded-full text-xs font-medium bg-red-300 text-white">{classification.classification}</span>);
                            break;
                          case 3:
                            return (<span key={index} className="inline-flex items-center gap-1.5 py-1.5 px-3 mr-2 rounded-full text-xs font-medium bg-yellow-400 text-white">{classification.classification}</span>);
                            break;
                          case 4:
                            return (<span key={index} className="inline-flex items-center gap-1.5 py-1.5 px-3 mr-2 rounded-full text-xs font-medium bg-green-300 text-white">{classification.classification}</span>);
                            break;
                          case 5:
                            return (<span key={index} className="inline-flex items-center gap-1.5 py-1.5 px-3 mr-2 rounded-full text-xs font-medium bg-green-500 text-white">{classification.classification}</span>);
                            break;
                          default:
                            return (<span key={index} className="inline-flex items-center gap-1.5 py-1.5 px-3 mr-2 rounded-full text-xs font-medium bg-green-500 text-white">{classification.classification}</span>);
                        }
                      }
                    )}
                  </div>
                </div>
              :
                <div className="flex flex-col space-y-2 mt-5">
                    {review.classification_list.map((classification, index)=>
                      <div className="flex flex-row ">
                        <strong className="mr-3">{classification.classification}</strong>
                        <Stars rating_value={classification.rating_value}/>
                      </div>
                    )}
                </div>
            : null}
            {review.sys_review_source && review.sys_review_source.id !== 1 ?
            <div className="xs:flex xs:flex-col space-y-2 sm:flex sm:flex-row mt-5">
              <div className="inline-flex shadow-sm">
                <button type="button" className="py-1 px-2 inline-flex justify-center items-center gap-2 first:rounded-l-md first:ml-0 last:rounded-r-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all text-sm dark:focus:ring-offset-gray-800" onClick={this.generate} disabled={loading}>
                  <span className="sm:text-xs md:text-sm">Generate Reply</span>
                  {loading ?
                    <span className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading"></span>
                  :
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                    </svg>
                  }
                </button>
                <a href={review.url} target="_blank" className="xs:min-w-full py-1 px-2 inline-flex justify-center items-center gap-2 sm:first:rounded-l-lg first:ml-0 last:rounded-r-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800 text-sm">
                  <span className="sm:text-xs md:text-sm">Link to Review</span>
                </a>
              </div>
            </div>
            : null}
          </div>
        )
      }
    }
  )
)
export default withParams(ReviewCard)
