import React, { Component } from 'react';
import { BrowserRouter as Router, Routes as Switch, Route, useParams } from 'react-router-dom'
import Brands from './brands';
import Orgs from './orgs';
import BrandProducts from './brand.products';
import BrandLocations from './brand.locations';
import BrandReviewSources from './brand.review.sources';
import AllReviewSources from './all.review.sources';
import OpenAI from './openai';
import ChatGPT from './chatgpt';
import OrgChatGPT from './org.chatgpt';
import OrgJob from './org.job';
import OrgJobList from './org.job.list';
import OrgReviews2 from './org.reviews';
import BrandHeatMap from './brand.heatmap';
import BrandHeatMap2 from './brand.heatmap.2';
import OrgQuestionnaireList from './org.questionnaire.list';
import OrgQuestionnaire from './org.questionnaire';
import JobResult from './job.result';
import Users from './users';

class AppRoutes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path='/' element={ <Orgs/> } />
          <Route path='/brand-products/:brand_hash_id' element={ <BrandProducts/> } />
          <Route path='/brand-locations/:brand_hash_id' element={ <BrandLocations/> } />
          <Route path='/brand/:brand_hash_id/review-source' element={ <BrandReviewSources/> } />
          <Route path='/bcon/:organization_hash_id/:brand_hash_id' element={ <ChatGPT/> } />
          <Route path='/ocon/:organization_hash_id' element={ <OrgChatGPT/> } />
          <Route path='/ojob/:organization_hash_id' element={ <OrgJob/> } />
          <Route path='/ojob/:organization_hash_id/:job_hash_id' element={ <OrgJob/> } />
          <Route path='/orev/:organization_hash_id' element={ <OrgReviews2/> } />
          <Route path='/job-result/:job_result_hash_id' element={ <JobResult/> } />
          <Route path='/ojob-list/:organization_hash_id' element={ <OrgJobList/> } />
          <Route path='/qr-naires/:organization_hash_id' element={ <OrgQuestionnaireList/> } />
          <Route path='/qr-naire/:organization_hash_id/:qr_hash_id' element={ <OrgQuestionnaire/> } />
          <Route path='/openai' element={ <OpenAI/> } />
          <Route path='/heat-map2/:organization_hash_id/:brand_hash_id' element={ <BrandHeatMap/> } />
          <Route path='/heat-map/:organization_hash_id/:brand_hash_id' element={ <BrandHeatMap2/> } />
          <Route path='/all-rs' element={ <AllReviewSources/> } />
          <Route path='/users' element={ <Users/> } />
        </Switch>
      </Router>
    )
  }
}

export default AppRoutes;

// <Route exact path="/attendance-log" component={AttendanceLog}/>
// <Route exact path="/planning-tool" component={PlanningTool}/>
// <Route exact path="/production-plan" component={ProductionPlan}/>
// <Route exact path="/donor-human-stool" component={SubstanceLots}/>
// <Route exact path="/batches" component={Batches}/>

// <Route exact path="/locations" component={Locations}/>
// <Route exact path="/location-waitlist/:locationHashId" component={LocationWaitlist}/>
// <Route exact path="/location-reservations/:locationHashId" component={Reservations}/>
// <Route exact path="/users" component={Users}/>
// <Route exact path="/incidents" component={Locations}/>
// <Route exact path="/reporting" component={Checkins}/>
// <Route exact path="/reporting-feedback/:questionnaire_hash_id" component={FeedbackReports}/>
// <Route exact path="/feedback/:business_hash_id" component={Questionnaires}/>
// <Route exact path="/incentives/:business_hash_id" component={Incentives}/>
// <Route exact path="/ci/:shortCode" render={(props) => <Waitlist {...props} currentState={1}/>}/>
// <Route exact path="/wait/:locationCode" render={(props) => <Waitlist {...props} currentState={1}/>}/>
// <Route exact path="/konva" component={KonvaTest}/>
// <Route exact path="/konva2" component={KonvaTest2}/>
// <Route exact path="/location-settings/:locationHashId" component={LocationSettings}/>
