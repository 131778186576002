import React, { Component } from 'react';

class FormattedReviewText extends Component{
  constructor(props){
    super(props);
    this.state = {
    }
  }

  render(){
    const {sentiment_json} = this.props;
    return (
      <p className="mb-4 text-base text-neutral-600">
        {sentiment_json.map((sentence, index) => {
            var style = {padding: '.2em', wordWrap:'break-word', backgroundColor: 'rgba(255, 255, 255, 1)'};
            if (sentence.sentiment[0].label){
              switch (sentence.sentiment[0].label) {
                case 'POS':
                  style = {padding: '.2em', wordWrap:'break-word', backgroundColor: 'rgba(0, 255, 0, 1)'}
                  break;
                case 'NEG':
                  style = {padding: '.2em', wordWrap:'break-word', backgroundColor: 'rgba(255, 0, 0, .5)'}
                  break;
              }
            }
            return (
              <mark style={style} key={index}>
                {sentence.sentence}
              </mark>
            );
        })}
      </p>
    );
  }
}

export default FormattedReviewText;
